import { getLanguageByUri } from './langs';

export const loadData = function({
    api,
    cacheVersion,
    errorCallback,
    version,
    path,
    resolve_relations,
    langcode,
}) {
    let uri = `cdn/stories/${path}`;
    
    const language = getLanguageByUri(uri);
    
    if (language.default && language.notFound) {
        uri = uri.replace('cdn/stories/', `cdn/stories/${language.folder}/`);
    }
    
    return api
    .get(uri, {
        version: version,
        cv: cacheVersion,
        resolve_relations: 'global_reference.reference',
    })
    .then((res) => {
        return {
            ...res.data,
            langcode,
        };
    })
    .catch((res) => {
        if (!res.response) {
            console.error(res);
            errorCallback({
                statusCode: 404,
                message: 'Failed to receive content form api',
            });
        } else {
            console.log(path);
            console.error(res.response.data);
            errorCallback({
                statusCode: res.response.status,
                message: res.response.data,
            });
        }
    });
};

export const isEditMode = function(context) {
    let editMode = process.env.HEROKU === 'true' ? true : false;
    if (
        context.query._storyblok ||
        context.isDev ||
        (typeof window !== 'undefined' &&
            window.localStorage.getItem('_storyblok_draft_mode'))
    ) {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem('_storyblok_draft_mode', '1');
            if (window.location == window.parent.location) {
                window.localStorage.removeItem('_storyblok_draft_mode');
            }
        }
        
        editMode = true;
    }
    return editMode;
};

export const loadStoryBlokData = function(context, overridePath = '') {
    // Check if we are in the editing mode
    let editMode = isEditMode(context);
    
    let version = editMode ? 'draft' : 'published';
    let path = context.route.path;
    
    const langcode = getLanguageByUri(path).lang;
    
    // Load the JSON from the API
    return loadData({
        version: version,
        api: context.app.$storyapi,
        cacheVersion: context.store.state.cacheVersion,
        errorCallback: context.error,
        path: overridePath ? overridePath : path,
        resolve_relations: 'global_reference.reference',
        langcode,
    });
};

export const checkEvents = function() {
    this.$storybridge.on(['input', 'published', 'change'], (event) => {
        if (event.action == 'input') {
            if (event.story.id === this.story.id) {
                this.story.content = event.story.content;
            }
        } else if (!event.slugChanged) {
            window.location.reload();
        }
    });
};

export const storyData = function() {
    return { story: { content: {} } };
};

export const storyMixin = {
    data: function() {
        return {
            ...storyData(),
        };
    },
    
    mounted() {
        checkEvents.bind(this);
    },
    
    methods: {},
};
