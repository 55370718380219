
    import _ from 'lodash';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import eventBus from '../../helpers/eventBus.js';
    import { getInterestRatesBasic } from '../../helpers/adpi';
    
    export default {
        mixins: [SbComponentMixin],
        
        props: ['isTopFive'],
        
        data() {
            return {
                interestRates: [],
                loading: true,
                isShowAll: false,
            };
        },
        
        computed: {
            isMainPage() {
                const path = [
                    '/hypotheekrente/vergelijken',
                    '/expats/mortgage-rates/compare',
                ];
                
                return path?.includes(this.$route?.path);
            },
            
            filteredInterestRates: function() {
                return _.filter(this.interestRates, function(o) {
                    return o.rente > 0;
                });
            },
            
            topFive: function() {
                return this.sortedInterestRates?.splice(0, 5);
            },
            
            sortedInterestRates: function() {
                if (this.isMainPage && !this.isShowAll) {
                    // Top five
                    return _.orderBy(this.filteredInterestRates, 'rente')?.splice(0, 5);
                }
                
                return _.orderBy(this.filteredInterestRates, 'rente');
            },
        },
        
        mounted: function() {
            eventBus.$on('comparemortgagedetails:compare', (comparemortgagedetails) => {
                this.fetch(comparemortgagedetails);
            });
        },
        
        beforeDestroy() {
            eventBus.$off('comparemortgagedetails:compare', this.fetch);
        },
        
        methods: {
            async fetch(comparemortgagedetails) {
                this.interestRates = await getInterestRatesBasic(comparemortgagedetails)
                .then((data) => {
                    this.loading = false;
                    return data ? data.interestRates : [];
                })
                .catch((error) => console.error(error));
            },
            
            formatPercentage(value) {
                if (value || value === 0) {
                    return value.toString().replace('.', ',') + '%';
                }
                
                return value;
            },
            
            showAll() {
                this.isShowAll = true;
            },
        },
    };
