import Vue from 'vue';

Vue.filter('resize', (image, size) => {
    if (!image || typeof image === 'undefined') {
        return;
    }
    
    const url = new URL(image);
    return (
        '//img2.storyblok.com/' + size + url.pathname
    );
});

Vue.filter('dashify', function(value) {
    if (typeof value === 'undefined') {
        return 'undefined';
    }
    let dashified = value
    .toString()
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .toLowerCase()
    .replace(/[ _]/g, '-');
    
    return 'blok-' + dashified;
});
