
    import { SbComponentMixin } from '../mixins/sb-component-mixin';
    import { loadStoryBlokData } from '../helpers/storyblok-loader';
    import Navbar from './Main/Navbar';
    import TopBar from './Main/TopBar';
    import QuickNavigation from '/components/QuickNavigation/QuickNavigation';
    
    export default {
        components: { TopBar, Navbar, QuickNavigation },
        mixins: [SbComponentMixin],
        
        data: function() {
            return {
                navbar: null,
                topbar: null,
                footer: null,
            };
        },
        
        async fetch() {
            try {
                let path = '/global/navbar';
                
                let response = await loadStoryBlokData(this.$nuxt.context, path);
                this.navbar = response?.story?.content?.global[0];
            } catch (error) {
                console.log(path);
                console.log(error);
            }
            
            try {
                let path = '/global/topbar';
                let response = await loadStoryBlokData(this.$nuxt.context, path);
                this.topbar = response?.story?.content?.global[0];
            } catch (error) {
                console.log(path);
                console.log(error);
            }
            
            try {
                let path = '/global/footer';
                
                let response = await loadStoryBlokData(this.$nuxt.context, path);
                this.footer = response?.story?.content?.global[0];
                
                //Add navbar items in footer
                this.footer.items = this.navbar?.items;
            } catch (error) {
                console.log(path);
                console.log(error);
            }
        },
        
        head() {
            return {
                bodyAttrs: {
                    itemscope: 'itemscope',
                    itemtype: 'http://schema.org/WebPage',
                },
                title: this.blok.name,
                meta: [
                    {
                        hid: 'description',
                        name: 'description',
                        content: this.blok.metaTags,
                    },
                ],
            };
        },
    };
