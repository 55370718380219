import StoryblokClient from 'storyblok-js-client';

// init with access token
const client = new StoryblokClient({
    accessToken: process.env.STORYBLOK_TOKEN,
});

export const rtfRender = function(content) {
    client.setComponentResolver((component, blok) => {
        return `<component :blok='${JSON.stringify(blok)}'
                       key='${blok._uid}'
                       :is="'${component}' | dashify"></component>`;
    });
    return '<div>' + client.richTextResolver.render(content) + '</div>';
};
