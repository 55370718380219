
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { getLanguageByUri } from '/helpers/langs';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                isActive: false,
                isActiveDropDown: false,
            };
        },
        
        methods: {
            closeMenu() {
                this.isActive = false;
            },
            openMenu() {
                this.isActive = true;
            },
            
            toggleDropDown() {
                this.isActiveDropDown = this.isActiveDropDown ? false : true;
            },
        },
        
        computed: {
            navLinks() {
                // Array without the last two items
                return this.blok.items.slice(0, -2);
            },
            navLinksLastTwo() {
                if (this.langcode === 'en') {
                    // For english we need to return only this specific item
                    return this.blok?.items?.filter(item => item.name === 'Bibliotheek');
                }
                
                return this.blok?.items?.slice(-2) ?? [];
            },
            navLinksEnglishForMobile() {
                // For english we need to filter out FAQ
                if (this.langcode === 'en') {
                    // For english we need to return only this specific item
                    return this.blok?.items?.filter(item => item.name !== 'FAQ');
                }
            },
        },
        
        mounted() {
            this.langcode = getLanguageByUri(this.$route.path)?.lang;
        },
    };
