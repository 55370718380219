
    import { SbComponentMixin } from '../mixins/sb-component-mixin';
    import { ComponentBackgroundMixin } from '../mixins/component-background-mixin';
    
    export default {
        mixins: [SbComponentMixin, ComponentBackgroundMixin],
        
        computed: {
            titleStyle: function() {
                return {
                    color: this.blok.title_color ? this.blok.title_color.color : '#000',
                };
            },
            
            subtitleStyle: function() {
                return {
                    color: this.blok.subtitle_color
                        ? this.blok.subtitle_color.color
                        : '#000',
                };
            },
            
            textStyle: function() {
                return {
                    color: this.blok.text_color ? this.blok.text_color.color : '#000',
                };
            },
        },
    };
