
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {};
        },
        
        methods: {
            containVideo(filename) {
                if (!filename) return false;
                
                return filename.endsWith('.mp4') || filename.endsWith('.webm');
            },
        },
    };
