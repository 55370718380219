import { rtfRender } from './render-richtext';

export const GenerateInfowindow = function(title, content) {
    var renderedContent = rtfRender(content);
    
    var html = `<div id="content">
            <div id="siteNotice"></div>
            <h1 id="firstHeading" class="firstHeading">${title}</h1>
            <div id="bodyContent">
                <p>${renderedContent}</p>
            </div> 
        </div>`;
    
    return new google.maps.InfoWindow({
        content: html,
    });
};
