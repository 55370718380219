
    import { ComponentBackgroundMixin } from '../../mixins/component-background-mixin';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [ComponentBackgroundMixin, SbComponentMixin],
        
        data() {
            return {
                mortgage: [
                    {
                        name: 'Login',
                        name_en: 'Login',
                        href: 'https://ohao.klantcloud.nl/login',
                        href_en: 'https://ohao.klantcloud.nl/login',
                        target: '_blank',
                    },
                    {
                        name: 'Kennismaking',
                        name_en: 'Introductory',
                        href: '/planafspraak',
                        href_en: '/expats/schedule-a-call',
                        target: '',
                    },
                ],
                legal: [
                    {
                        name: 'Vergelijkingskaart',
                        href: '/files/Vergelijkingskaart_Hypotheek_OHAO.pdf',
                        download: true,
                    },
                    {
                        name: 'Dienstenwijzer',
                        href: '/files/Dienstenwijzer_OHAO.pdf',
                        download: true,
                    },
                    {
                        name: 'Beloningsbeleid',
                        href: '/files/Beheerst_beloningsbeleid_OHAO.pdf',
                        download: true,
                    },
                ],
            };
        },
        
        computed: {
            general() {
                // Array without the last two items
                return this.blok?.items?.slice(0, -2) ?? [];
            },
        },
    };
