
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { ComponentBackgroundMixin } from '../../mixins/component-background-mixin';
    import { getGeldverstrekkers } from '../../helpers/adpi';
    
    export default {
        mixins: [SbComponentMixin, ComponentBackgroundMixin],
        
        data: function() {
            return {
                lenders: [],
                cycledLenders: [],
                start: 0,
                cyclingInterval: null,
                cyclingTime: 3000,
                pauseCycle: false,
                hidden: null,
                cycledLendersWaitinRoom: null,
                windowWidth: null,
            };
        },
        
        mounted: function() {
            this.loadGeldverstrekkers();
            
            this.windowWidth = window.innerWidth;
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth;
            });
        },
        
        async fetch() {
            this.lenders = await getGeldverstrekkers()
            .then((data) => {
                // return data ? this.shuffle(data.geldverstrekkers) : [];
                return data.geldverstrekkers;
            })
            .catch((error) => console.error(error));
        },
        
        methods: {
            async loadGeldverstrekkers() {
                if (this.lenders.length === 0) {
                    this.lenders = await getGeldverstrekkers().then((data) =>
                        data ? this.shuffle(data.geldverstrekkers) : [],
                    );
                }
            },
            
            shuffle(array) {
                var currentIndex = array.length, temporaryValue, randomIndex;
                
                // While there remain elements to shuffle...
                while (0 !== currentIndex) {
                    
                    // Pick a remaining element...
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex -= 1;
                    
                    // And swap it with the current element.
                    temporaryValue = array[currentIndex];
                    array[currentIndex] = array[randomIndex];
                    array[randomIndex] = temporaryValue;
                }
                
                return array;
            },
        },
    };
