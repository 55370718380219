
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {};
        },
        computed: {
            currentYear() {
                return new Date().getFullYear();
            },
        },
    };
