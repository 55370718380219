
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    const stats = [
        {
            label: 'Total downloads',
            value: 1,
        },
        {
            label: 'Total',
            value: 2,
        },
        {
            label: 'downloads',
            value: 3,
        },
        {
            label: 'Hallo',
            value: 4,
        },
    ];
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                stats,
            };
        },
    };
