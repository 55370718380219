
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { rtfRender } from '../../helpers/render-richtext';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {};
        },
        
        computed: {
            transformedContent() {
                if (!this.blok && !this.blok.text) return;
                
                return rtfRender(this.blok.text);
            },
        },
    };
