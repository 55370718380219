
    import { debounce } from '/helpers/debounce';
    import { calculateExpense, calculateMaximumMortgage } from '/helpers/adpi';
    import { APPRAISAL_FEE, BROKER_RATE, MORTGAGE_ADVICE_FEE, NOTARY_FEE, TAX_RATE } from '/constants/Mortgage';
    
    export default {
        props: {
            blok: {
                type: Object,
                required: false,
                default: () => {
                },
            },
            langcode: {
                type: String,
                default: 'nl',
                required: false,
            },
        },
        
        data() {
            return {
                formSchema: {
                    yearlyIncome: null,
                    yearlyIncomePartner: null,
                    interestRate: null,
                    repaymentType: 'annuitair',
                    energyCertificate: '13 Nul-op-de-Meter',
                    valueForLtv: null,
                    amountNonDeductibleCredit: null,
                    amountNonDeductibleStudentLoan: null,
                    studentLoanRegime: 'old',
                    useNewNorm: true,
                },
                
                energyCertificateOptions: [
                    { text: 'Nul-op-de-meter', value: '13 Nul-op-de-Meter' },
                    { text: 'Energie Neutraal', value: '12 Energie Neutraal' },
                    { text: 'A++++', value: '11 A++++' },
                    { text: 'A+++', value: '10 A+++' },
                    { text: 'A++', value: '01 A++' },
                    { text: 'A+', value: '02 A+' },
                    { text: 'A', value: '03 A' },
                    { text: 'B', value: '04 B' },
                    { text: 'C', value: '05 C' },
                    { text: 'D', value: '06 D' },
                    { text: 'E', value: '07 E' },
                    { text: 'F', value: '08 F' },
                    { text: 'G', value: '09 G' },
                ],
                
                isLoading: false,
                
                maximumMortgage: 0,
                grossMonthlyExpense: 0,
                
                errors: [],
            };
        },
        
        methods: {
            getMaximumMortgage: debounce(async function() {
                try {
                    if ( //Required fields
                        this.formSchema.yearlyIncome &&
                        this.formSchema.interestRate &&
                        this.formSchema.repaymentType
                    
                    ) {
                        this.isLoading = true;
                        
                        this.maximumMortgage = await calculateMaximumMortgage(this.formSchema);
                        await this.getExpense();
                        
                        this.isLoading = false;
                    }
                    
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }
            }, 250),
            
            async getExpense() {
                try {
                    if ( //Required fields
                        this.formSchema.valueForLtv &&
                        this.maximumMortgage &&
                        this.formSchema.interestRate &&
                        this.formSchema.repaymentType
                    
                    ) {
                        this.isLoading = true;
                        
                        const amountDeductible = Math.min(this.maximumMortgage, this.formSchema.valueForLtv);
                        
                        let payload = {
                            ...this.formSchema,
                            amountDeductible,
                        };
                        
                        this.grossMonthlyExpense = await calculateExpense(payload);
                        
                        this.isLoading = false;
                    }
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }
            },
            
            formatNumber: function(number) {
                if (!number && number !== 0) number = 0;
                
                return new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                }).format(number);
            },
        },
        
        computed: {
            studentLoanIsFilled() {
                return this.formSchema.amountNonDeductibleStudentLoan > 0;
            },
            
            contributionOfOwnFunds() {
                if (this.formSchema.valueForLtv > 0) {
                    const contributionOfOwnFunds = {
                        minimumOwnFunds: 0,
                        taxAmount: this.formSchema.valueForLtv * TAX_RATE,
                        brokerAmount: this.formSchema.valueForLtv * BROKER_RATE,
                        appraisalFee: APPRAISAL_FEE,
                        mortgageAdviceFee: MORTGAGE_ADVICE_FEE,
                        notaryFee: NOTARY_FEE,
                    };
                    
                    if (this.maximumMortgage < this.formSchema.valueForLtv) {
                        contributionOfOwnFunds.minimumOwnFunds = this.formSchema.valueForLtv - this.maximumMortgage;
                    }
                    
                    contributionOfOwnFunds.total =
                        contributionOfOwnFunds.minimumOwnFunds +
                        contributionOfOwnFunds.taxAmount +
                        contributionOfOwnFunds.brokerAmount +
                        contributionOfOwnFunds.appraisalFee +
                        contributionOfOwnFunds.mortgageAdviceFee +
                        contributionOfOwnFunds.notaryFee;
                    
                    return contributionOfOwnFunds;
                }
                
                return {
                    total: 0,
                };
            },
        },
        
        watch: {
            formSchema: {
                handler(value) {
                    this.getMaximumMortgage();
                },
                deep: true,
            },
        },
    };
