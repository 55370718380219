
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { isEditMode } from '../../helpers/storyblok-loader';
    import moment from 'moment/moment';
    import StoryblokClient from 'storyblok-js-client';
    
    const token = process.env.STORYBLOK_TOKEN;
    
    // init with access token
    const client = new StoryblokClient({
        accessToken: token,
    });
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                blogs: [],
            };
        },
        
        async mounted() {
            await this.getBlogItems();
        },
        
        async fetch() {
            this.blogs = await this.getBlogItems(this.$nuxt.context);
        },
        
        methods: {
            getBlogItems: async function(context) {
                let version = 'draft';
                
                if (context) {
                    version = isEditMode(context) ? 'draft' : 'published';
                }
                
                const folderName = this?.langcode === 'en' ? 'expats/library' : 'nl/bibliotheek';
                
                const response = await client.get('cdn/stories', {
                    starts_with: folderName,
                    version,
                });
                
                if (!response || !response.data || !response.data.stories) {
                    return;
                }
                
                const blogs = response.data.stories
                .filter((x) => x.slug !== 'bibliotheek' && x.slug !== 'library')
                .sort(
                    (a, b) =>
                        new Date(b.first_published_at) - new Date(a.first_published_at),
                );
                
                return blogs;
            },
            
            getSrc(img) {
                if (img) {
                    return img.filename ?? '';
                }
                
                return null;
            },
            
            getAlt(img) {
                if (img) {
                    return img.alt ?? '';
                }
                
                return null;
            },
            
            getPublishedAt(blog) {
                if (!blog.first_published_at) {
                    return '';
                }
                moment.locale('nl');
                return moment.utc(blog.first_published_at).local().calendar();
            },
        },
    };
