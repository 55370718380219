
    import { SbComponentMixin } from '/mixins/sb-component-mixin.js';
    import eventBus from '../../helpers/eventBus.js';
    
    export default {
        mixins: [SbComponentMixin],
        
        props: {
            langcode: {
                type: String,
                default: 'nl',
            },
        },
        
        data() {
            return {
                fixedTerm: [
                    { text: '1', value: 12 },
                    { text: '2', value: 24 },
                    { text: '3', value: 36 },
                    { text: '4', value: 48 },
                    { text: '5', value: 60 },
                    { text: '6', value: 72 },
                    { text: '7', value: 84 },
                    { text: '10', value: 120 },
                    { text: '12', value: 144 },
                    { text: '15', value: 180 },
                    { text: '17', value: 204 },
                    { text: '20', value: 240 },
                    { text: '25', value: 300 },
                    { text: '30', value: 360 },
                ],
                
                paymentType: [
                    { text: 'Annuitair', value: 'annuitair' },
                    { text: 'Lineair', value: 'linear' },
                    { text: 'Aflosvrij', value: 'aflosvrij' },
                ],
                
                nhg: [
                    { text: 'Ja', value: 1 },
                    { text: 'Nee', value: 0 },
                ],
                
                energyCertificate: [
                    { text: 'Nul-op-de-meter', value: '13 Nul-op-de-Meter' },
                    { text: 'Energie Neutraal', value: '12 Energie Neutraal' },
                    { text: 'A++++', value: '11 A++++' },
                    { text: 'A+++', value: '10 A+++' },
                    { text: 'A++', value: '01 A++' },
                    { text: 'A+', value: '02 A+' },
                    { text: 'A', value: '03 A' },
                    { text: 'B', value: '04 B' },
                    { text: 'C', value: '05 C' },
                    { text: 'D', value: '06 D' },
                    { text: 'E', value: '07 E' },
                    { text: 'F', value: '08 F' },
                    { text: 'G', value: '09 G' },
                ],
                
                debtToMarketRatio: [
                    {
                        text: '10%',
                        value: {
                            requiredMortgage: 10000,
                            propertyValue: 100000,
                        },
                    },
                    {
                        text: '20%',
                        value: {
                            requiredMortgage: 20000,
                            propertyValue: 100000,
                        },
                    },
                    {
                        text: '30%',
                        value: {
                            requiredMortgage: 30000,
                            propertyValue: 100000,
                        },
                    },
                    {
                        text: '40%',
                        value: {
                            requiredMortgage: 40000,
                            propertyValue: 100000,
                        },
                    },
                    {
                        text: '50%',
                        value: {
                            requiredMortgage: 50000,
                            propertyValue: 100000,
                        },
                    },
                    {
                        text: '60%',
                        value: {
                            requiredMortgage: 60000,
                            propertyValue: 100000,
                        },
                    },
                    {
                        text: '70%',
                        value: {
                            requiredMortgage: 70000,
                            propertyValue: 100000,
                        },
                    },
                    {
                        text: '80%',
                        value: {
                            requiredMortgage: 80000,
                            propertyValue: 100000,
                        },
                    },
                    {
                        text: '90%',
                        value: {
                            requiredMortgage: 90000,
                            propertyValue: 100000,
                        },
                    },
                    {
                        text: '100%',
                        value: {
                            requiredMortgage: 100000,
                            propertyValue: 100000,
                        },
                    },
                ],
                
                formSchema: {
                    energyCertificate: '13 Nul-op-de-Meter',
                    fixedTerm: 12,
                    nhg: 1,
                    paymentType: 'annuitair',
                    propertyValue: 100000,
                    requiredMortgage: 100000,
                },
                
                urlStructure: {
                    energyCertificate: 'Nul-op-de-meter',
                    fixedTerm: '1',
                    ltv: 'NHG',
                    paymentType: 'annuitair',
                },
            };
        },
        
        created() {
            this.translateOptionsText();
            this.mapParamToValue();
            
            this.$nextTick(function() {
                eventBus.$emit(
                    'comparemortgagedetails:compare',
                    this.formSchema,
                );
            });
        },
        
        methods: {
            setEnergyCertificate(value) {
                if (!value) return;
                this.mapParamToValue();
                
                const urlStructure = {
                    ...this.urlStructure,
                    energyCertificate: value.text,
                };
                
                return this.constructedUrl(urlStructure);
            },
            
            setNhg(value) {
                if (!value && value !== 0) return;
                
                const urlStructure = {
                    ...this.urlStructure,
                    ltv: 'NHG',
                };
                
                return this.constructedUrl(urlStructure);
            },
            
            setPaymentType(item) {
                if (!item) return;
                this.mapParamToValue();
                
                const urlStructure = {
                    ...this.urlStructure,
                    paymentType: item.text,
                };
                
                return this.constructedUrl(urlStructure);
            },
            
            setFixedTerm(value) {
                if (!value) return;
                this.mapParamToValue();
                
                let urlStructure = {
                    ...this.urlStructure,
                    fixedTerm: value.text,
                };
                
                return this.constructedUrl(urlStructure);
            },
            
            setDebtToMarketRatio(item) {
                if (!item) return;
                this.mapParamToValue();
                
                const urlStructure = {
                    ...this.urlStructure,
                    ltv: item.text.slice(0, -1), //Remove % sign
                };
                return this.constructedUrl(urlStructure);
            },
            
            mapParamToValue() {
                const regex = /(-)(.*?)$/g; // matches everything after the first dash
                
                let fixedTermText = this.$route?.params?.fixedTerm?.match(regex)?.[0]?.substring(1);
                let fixedTermValue = this.fixedTerm?.find((item) => item.text?.toUpperCase() === fixedTermText?.toUpperCase())?.value;
                if (fixedTermValue) {
                    this.formSchema.fixedTerm = fixedTermValue;
                    this.urlStructure.fixedTerm = fixedTermText;
                }
                
                let paymentTypeText = this.$route?.params?.repaymenttype?.match(regex)?.[0]?.substring(1);
                let paymentTypeValue = this.paymentType?.find((item) => item.text?.toUpperCase() === paymentTypeText?.toUpperCase())?.value;
                if (paymentTypeValue) {
                    this.formSchema.paymentType = paymentTypeValue;
                    this.urlStructure.paymentType = paymentTypeText;
                }
                
                let ltvText = this.$route?.params?.ltv?.match(regex)?.[0]?.substring(1);
                if (ltvText === 'NHG') {
                    this.formSchema.nhg = 1;
                    this.formSchema.requiredMortgage = 100000;
                    this.formSchema.propertyValue = 100000;
                    this.urlStructure.ltv = ltvText;
                }
                
                let ltvValue = this.debtToMarketRatio?.find((item) => item.text.slice(0, -1)?.toUpperCase() === ltvText?.toUpperCase())?.value;
                if (ltvValue) {
                    this.formSchema.nhg = 0;
                    this.formSchema.requiredMortgage = ltvValue.requiredMortgage;
                    this.formSchema.propertyValue = ltvValue.propertyValue;
                    this.urlStructure.ltv = ltvText;
                }
                
                let energyLabelText = this.$route?.params?.energylabel?.match(regex)?.[0]?.substring(1);
                let energyCertificateValue = this.energyCertificate?.find((item) => item.text?.toUpperCase() === energyLabelText?.toUpperCase())?.value;
                if (energyCertificateValue) {
                    this.formSchema.energyCertificate = energyCertificateValue;
                    this.urlStructure.energyCertificate = energyLabelText;
                }
            },
            
            translateOptionsText() {
                if (this.langcode === 'en') {
                    this.urlStructure.paymentType = 'Annuity';
                    this.urlStructure.energyCertificate = 'Zero-energy-cost';
                    
                    this.paymentType[0].text = 'Annuity';
                    this.paymentType[1].text = 'Linear';
                    this.paymentType[2].text = 'Interest-only';
                    
                    this.energyCertificate[0].text = 'Zero-energy-cost';
                    this.energyCertificate[1].text = 'Energy-neutral';
                }
            },
            
            constructedUrl(urlStructure) {
                let url = '';
                
                if (this.langcode === 'nl') {
                    url = `/hypotheekrente/rentevasteperiode-${urlStructure.fixedTerm}/hypotheekvorm-${urlStructure.paymentType.toLowerCase()}/ltv-${urlStructure.ltv.toLowerCase()}/energielabel-${encodeURIComponent(urlStructure.energyCertificate.toLowerCase())}/vergelijken`;
                }
                
                if (this.langcode === 'en') {
                    url = `/expats/mortgage-rates/fixedterm-${urlStructure.fixedTerm}/paymenttype-${urlStructure.paymentType.toLowerCase()}/ltv-${urlStructure.ltv.toLowerCase()}/energylabel-${encodeURIComponent(urlStructure.energyCertificate.toLowerCase())}/compare`;
                }
                
                return url;
            },
        },
        
        computed: {
            isMainPage() {
                const path = [
                    '/hypotheekrente/vergelijken',
                    '/expats/mortgage-rates/compare',
                ];
                
                return path?.includes(this.$route?.path);
            },
        },
        
        head() {
            let title = '';
            let content = '';
            
            if (this.langcode === 'nl') {
                title = `Vergelijk de hypotheek rente met aflosvorm ${this.urlStructure.paymentType} en een rentevastperiode van ${this.urlStructure.fixedTerm} jaar`;
                content = `Vergelijk hypotheekrentes van alle banken en geldverstrekkers. Bereken de maandlasten en de totale kosten van je hypotheek. Bekijk de hypotheekrente voor ${this.urlStructure.fixedTerm} jaar rentevast, ${this.urlStructure.paymentType} en ${this.urlStructure.ltv} LTV. Bekijk de hypotheekrente voor energielabel ${this.urlStructure.energyCertificate}`;
            }
            
            if (this.langcode === 'en') {
                title = `Compare Dutch mortgage interest rates with repayment form ${this.urlStructure.paymentType} with a ${this.urlStructure.fixedTerm} year fixed-term`;
                content = `Compare mortgage rates from all banks and lenders. Calculate the monthly fees and total cost of your mortgage. View the mortgage rates for ${this.urlStructure.fixedTerm} year fixed term, ${this.urlStructure.paymentType} and ${this.urlStructure.ltv} LTV. View mortgage rates for energy label ${this.urlStructure.energyCertificate}`;
                
            }
            
            if (this?.$route?.path === '/hypotheekrente/vergelijken') {
                title = `Vergelijk actuele hypotheekrente | OHAO`;
                content = `Vergelijk en krijg actuele hypotheekrente in één overzicht | OHAO`;
            }
            
            if (this?.$route?.path === '/expats/mortgage-rates/compare') {
                title = `Compare current mortgage rates | OHAO`;
                content = `Compare and get up-to-date mortgage rates in one overview | OHAO`;
            }
            
            return {
                title: title,
                meta: [
                    {
                        hid: title,
                        name: title,
                        content: content,
                    },
                ],
            };
        },
    };
