
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        mounted: function() {
            let oploc = this.getParameterByName('answer_3');
            
            if (this.langcode === 'nl') {
                if (['Ja', 'Weet ik nog niet'].includes(oploc)) {
                    this.$gtm.push({ event: 'fysiekNL' });
                } else {
                    this.$gtm.push({ event: 'onlineNL' });
                }
            } else {
                if (['Yes', 'I don\'t know yet'].includes(oploc)) {
                    this.$gtm.push({ event: 'fysiekExpat' });
                } else {
                    this.$gtm.push({ event: 'onlineExpat' });
                }
            }
        },
        
        methods: {
            getParameterByName(name) {
                var match = RegExp('[?&]' + name + '=([^&]*)').exec(
                    window.location.search,
                );
                return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
            },
        },
    };
