
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import SmallReviews from '../Advieskeuze/SmallReviews';
    
    export default {
        components: { SmallReviews },
        mixins: [SbComponentMixin],
        
        data() {
            return {};
        },
    };
