
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import axios from 'axios';
    import gsap from 'gsap';
    
    export default {
        mixins: [SbComponentMixin],
        
        data: function() {
            return {
                reviewCount: null,
                setReviewCount: null,
                overallScore: null,
                observer: null,
                tweenedNumber: 0,
            };
        },
        
        async fetch() {
            let reviewsReturn = await this.getReviews();
            
            this.reviewCount = reviewsReturn.ReviewCount.ReviewCount;
            
            let overallScore = reviewsReturn.ReviewCount.Score / 2;
            this.overallScore = Math.floor(overallScore * 10) / 10;
        },
        
        watch: {
            setReviewCount: function(newValue) {
                this.tweenedNumber = this.reviewCount - 40;
                gsap.to(this.$data, { duration: 2, tweenedNumber: newValue });
            },
        },
        
        mounted() {
            this.$nextTick(function() {
                this.addingAnimationClasses();
                this.addingCountingAnimation();
            });
        },
        
        computed: {
            animatedReviewCount: function() {
                return this.tweenedNumber.toFixed(0);
            },
        },
        
        destroyed() {
            this.observer.disconnect();
            this.countObserver.disconnect();
        },
        
        methods: {
            async getReviews() {
                return await axios
                .get(process.env.ADVIESKEUZE_API)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => console.log(error));
            },
            
            async addingCountingAnimation() {
                const reviewCounter = document.querySelector('.js-start-count-on-scroll');
                
                this.countObserver = new IntersectionObserver(([reviewCounter]) => {
                    if (reviewCounter && reviewCounter.isIntersecting) {
                        this.setReviewCount = this.reviewCount;
                    } else {
                        // entry.target.classList.remove("motion-safe:animate-fadeIn");
                    }
                });
                
                this.countObserver.observe(reviewCounter);
            },
            
            async addingAnimationClasses() {
                const targets = document.querySelectorAll('.js-show-on-scroll');
                
                this.observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry && entry.isIntersecting) {
                            entry.target.classList.add('motion-safe:animate-fadeIn');
                        } else {
                            entry.target.classList.remove('motion-safe:animate-fadeIn');
                        }
                    });
                });
                
                for (var i = 0, target; target = targets[i]; i++) {
                    target.classList.add('opacity-0');
                    this.observer.observe(target);
                }
            },
        },
    };
