
    import { ComponentBackgroundMixin } from '../../mixins/component-background-mixin';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [ComponentBackgroundMixin, SbComponentMixin],
        
        data() {
            return {
                showMobileNav: false,
            };
        },
    };
