
    import { ComponentBackgroundMixin } from '../../mixins/component-background-mixin';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { VueperSlide, VueperSlides } from 'vueperslides';
    import 'vueperslides/dist/vueperslides.css';
    
    export default {
        mixins: [ComponentBackgroundMixin, SbComponentMixin],
        components: { VueperSlides, VueperSlide },
    };
