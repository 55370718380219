
    import { CollapseTransition } from 'vue2-transitions';
    
    const tailwindConfig = require('../../tailwind.config');
    
    export default {
        name: 'VueFaqAccordion',
        
        components: {
            CollapseTransition,
        },
        
        data() {
            return {
                activeTab: '',
                activeQuestionIndex: null,
                showAccordion: true,
            };
        },
        props: {
            /**
             * Array of items
             * Object style {questionProperty: string, answerProperty: string, tabName: string}
             * You can change object keys names using other props (questionProperty, answerProperty, tabName)
             */
            items: {
                type: Array,
                required: true,
            },
            /**
             * Key name of object in items array for specifying title of question
             */
            questionProperty: {
                type: String,
                default: 'title',
            },
            /**
             * Key name of object in items array for specifying content text of open question
             */
            answerProperty: {
                type: String,
                default: 'value',
            },
            /**
             * Key name of object in items array for specifying navigation tab name
             */
            tabName: {
                type: String,
                default: 'category',
            },
            /**
             * Color for hover and active tab/question
             * possible values: 'red', '#F00', 'rgb(255, 0, 0)'
             */
            activeColor: {
                type: String,
                default: '',
            },
            /**
             * Color for borders
             */
            borderColor: {
                type: String,
                default: '#9e9e9e',
            },
            /**
             * Color for fonts
             */
            fontColor: {
                type: String,
                default: '#000000',
            },
            /**
             * Opened by default tabName (category)
             */
            initialTab: {
                type: String,
                default: null,
            },
            /**
             * Opened by default question
             * All closed by default
             */
            initialQuestionIndex: {
                nullable: true,
                type: Number,
                default: null,
            },
        },
        computed: {
            categories() {
                const uniqueCategories = this.items
                .map(item => item[this.tabName])
                .filter((category, index, categories) => categories.indexOf(category) === index);
                this.activeTab = this.initialTab || uniqueCategories[0];
                this.activeQuestionIndex = this.initialQuestionIndex || null;
                return uniqueCategories;
            },
            categoryItems() {
                return this.items
                .filter(item => item[this.tabName] === this.activeTab);
            },
            hasNavigation() {
                return !!this.categories[0];
            },
        },
        methods: {
            makeActive(itemIndex) {
                this.activeQuestionIndex = this.activeQuestionIndex === itemIndex ? null : itemIndex;
                this.$emit('itemSelect', { itemIndex });
            },
            generateButtonClasses(buttonIndex) {
                return [
                    'accordion__toggle-button',
                    this.activeQuestionIndex === buttonIndex
                        ? 'accordion__toggle-button_active'
                        : null,
                ];
            },
            generateQuestionClasses(questionIndex) {
                return [
                    'accordion__title',
                    this.activeQuestionIndex === questionIndex
                        ? 'accordion__title_active'
                        : null,
                ];
            },
            makeActiveCategory(category, categoryIndex) {
                if (this.activeTab === category) return;
                this.showAccordion = false;
                this.activeTab = category;
                this.activeQuestionIndex = null;
                setTimeout(() => {
                    this.$emit('categorySelect', { categoryIndex });
                    this.showAccordion = true;
                }, 300);
            },
            generateCategoryClasses(category) {
                return [
                    'faq__nav-item',
                    this.activeTab === category
                        ? 'faq__nav-item_active'
                        : null,
                ];
            },
        },
        mounted() {
            this.$refs.rootEl.style.setProperty('--active-color', this.activeColor);
            this.$refs.rootEl.style.setProperty('--border-color', this.borderColor);
            this.$refs.rootEl.style.setProperty('--font-color', this.fontColor);
        },
    };
