
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { ComponentBackgroundMixin } from '../../mixins/component-background-mixin';
    import { rtfRender } from '../../helpers/render-richtext';
    
    export default {
        mixins: [SbComponentMixin, ComponentBackgroundMixin],
        
        computed: {
            renderText() {
                return rtfRender(this.blok.content);
            },
        },
    };
