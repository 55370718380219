export default {
    'title': 'Heldere tarieven',
    'subTitle': 'Start altijd met een uitgebreide gratis oriëntatiefase',
    'whatIsSituation': 'Wat is je situatie?',
    'whatIsIncluded': 'Wat is inbegrepen?',
    'includedOne': 'Gratis oriëntatiefase',
    'includedTwo': 'Volledig advies',
    'includedThree': 'Uitgebreid rapport',
    'includedFour': 'Afsluiten van de hypotheek (geen afsluitkosten vanuit de geldverstrekker)',
};
