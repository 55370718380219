
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import {
        INCREASING_PRICE_DUTCH,
        INCREASING_PRICE_ENGLISH,
        MOVING_PRICE_DUTCH,
        MOVING_PRICE_ENGLISH,
        REFINANCING_PRICE_DUTCH,
        REFINANCING_PRICE_ENGLISH,
        STARTING_PRICE_DUTCH,
        STARTING_PRICE_ENGLISH,
    } from '@/constants/prices.js';
    
    export default {
        mixins: [SbComponentMixin],
        
        components: {},
        
        props: {
            blok: {
                type: Object,
                required: false,
                default: () => {
                },
            },
        },
        
        data: function() {
            return {
                activePrice: 'starting',
            };
        },
        
        mounted() {
            this.$i18n.setLocale(this.langcode);
        },
        
        computed: {
            includedFeatures: function() {
                return [
                    this.$i18n.t('ohao.pricing.includedOne'),
                    this.$i18n.t('ohao.pricing.includedTwo'),
                    this.$i18n.t('ohao.pricing.includedThree'),
                    this.$i18n.t('ohao.pricing.includedFour'),
                ];
            },
            
            pricingOptions: function() {
                if (this.langcode === 'nl') {
                    return {
                        'starting': {
                            label: 'Starter', value: STARTING_PRICE_DUTCH,
                        },
                        'increasing': {
                            label: 'Verhogen', value: INCREASING_PRICE_DUTCH,
                        },
                        'refinancing': {
                            label: 'Oversluiten', value: REFINANCING_PRICE_DUTCH,
                        },
                        'moving': {
                            label: 'Verhuizen', value: MOVING_PRICE_DUTCH,
                        },
                    };
                }
                
                return {
                    'starting': {
                        label: 'First-time buyer', value: STARTING_PRICE_ENGLISH,
                    },
                    'increasing': {
                        label: 'Increasing', value: INCREASING_PRICE_ENGLISH,
                    },
                    'refinancing': {
                        label: 'Refinancing', value: REFINANCING_PRICE_ENGLISH,
                    },
                    'moving': {
                        label: 'Relocating', value: MOVING_PRICE_ENGLISH,
                    },
                };
            },
        },
    };
