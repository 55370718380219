
    import { ComponentBackgroundMixin } from '../../mixins/component-background-mixin';
    import { TextMixin } from '../../mixins/text-mixin';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { rtfRender } from '../../helpers/render-richtext.js';
    import { get } from 'lodash';
    
    export default {
        mixins: [ComponentBackgroundMixin, SbComponentMixin, TextMixin],
        
        computed: {
            body: function() {
                return rtfRender(this.blok.body);
            },
            
            button1: function() {
                return this.blok.button ? this.blok.button[0] : false;
            },
            
            justify: function() {
                return {
                    'text-align': get(this.blok, 'text_alignment', false),
                };
            },
        },
    };
