import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Page from '~/components/Page.vue';
import BlogPost from '~/components/Blog.vue';

import TableOfContents from '~/components/Blog/TableOfContents.vue';
import TableOfContent from '~/components/Blog/TableOfContent.vue';

import Services from '~/components/Services.vue';
import Service from '~/components/Service.vue';
import Hero from '~/components/Heroes/Hero.vue';
import TwoButtonHero from '~/components/Heroes/TwoButtonHero.vue';
import SubHero from '~/components/Main/SubHero.vue';
import TextImg from '~/components/TextImg.vue';
import GoogleMap from '../components/Contact/GoogleMap';
import ContactDetails from '../components/Contact/ContactDetails';
import SeduceAppointment from '../components/Contact/SeduceAppointment';
import TestimonialSlide from '../components/TestimonialSlide/TestimonialSlide.vue';
import TestimonialSlider from '../components/TestimonialSlide/TestimonialSlider.vue';
import PriceTags from '../components/PriceTag/PriceTags.vue';
import PriceTag from '../components/PriceTag/PriceTag.vue';
import PriceMainSummary from '../components/PriceTag/PriceMainSummary';
import ExtraServices from '../components/PriceTag/ExtraServices.vue';
import ExtraService from '../components/PriceTag/ExtraService.vue';
import Navbar from '../components/Main/Navbar';
import TopBar from '../components/Main/TopBar';
import Footer from '../components/Main/Footer';
import PageBlock from '../components/Main/PageBlock';
import PageBlockFullHeight from '../components/Main/PageBlockFullHeight';
import PageBlockTwoColumns from '../components/Main/PageBlockTwoColumns';
import CallToActionWrapper from '../components/CallToAction/CallToActionWrapper';
import CallToAction from '../components/CallToAction/CallToAction';
import Usp from '../components/Usp/Usp';
import Usps from '../components/Usp/Usps';
import UspBig from '../components/Usp/UspBig';
import UspsBig from '../components/Usp/UspsBig';
import LendersOverview from '../components/Lenders/LendersOverview';
import AppointmentButton from '../components/AppointmentButton';
import AppointmentCalendar from '../components/Contact/AppointmentCalendar';
import BookingConfirmation from '../components/Contact/BookingConfirmation';
import TextBlok from '../components/Base/Text';
import DescriptionList from '../components/Base/DescriptionList';
import DescriptionListItem from '../components/Base/DescriptionListItem';
import Image from '../components/Base/Image';
import Reviews from '../components/Advieskeuze/Reviews';
import Testimonials from '../components/Main/Testimonials';
import Testimonial from '../components/Main/Testimonial';
import TeamMember from '../components/OverOns/TeamMember';
import TeamMembers from '../components/OverOns/TeamMembers';
import Step from '../components/Steps/Step';
import Steps from '../components/Steps/Steps';
import Timeline from '../components/Timeline/Timeline';
import Timelines from '../components/Timeline/Timelines';
import Faq from '../components/Faq/Faq';
import Faqs from '../components/Faq/Faqs';
import DynamicForm from '../components/Form/DynamicForm';
import CompareMortgageProviderForm from '../components/CompareMortgageProvider/CompareMortgageProviderForm';
import CompareMortgageProviderDisplay from '../components/CompareMortgageProvider/CompareMortgageProviderDisplay';
import ClientOnly from 'vue-client-only';
import VRuntimeTemplate from 'v-runtime-template';
import BasicButton from '../components/Base/BasicButton.vue';
import BasicButtonWrapper from '../components/Base/BasicButtonWrapper.vue';
import TableUsp from '../components/Base/TableUsp.vue';
import TableUspItem from '../components/Base/TableUspItem.vue';
import Container from '../components/Base/Container.vue';
import NavbarItem from '../components/Main/NavbarItem.vue';
import Headline from '../components/Main/Headline.vue';
import ReviewGrade from '../components/Advieskeuze/ReviewGrade';
import OhaoServicesFrontPage from '../components/Ohao/OhaoServicesFrontPage';
import Global from '../components/GLobal/Global';
import GlobalReference from '../components/GLobal/GlobalReference';
import NavbarLinksCentered from '../components/Navbars/NavbarLinksCentered';
import HeroWithAppScreenshot from '../components/Heros/HeroWithAppScreenshot';
import PageSimple from '../components/Layouts/PageSimple';
import LogoCloudHeadingBrand from '../components/LogoClouds/LogoCloudHeadingBrand';
import CtaCentered from '../components/Ctas/CtaCentered';
import CtaCenterBranded from '../components/Ctas/CtaCenterBranded';
import CtaBrandPanelWithAppScreenshot from '../components/Ctas/CtaBrandPanelWithAppScreenshot';
import FeatureSectionSideBySideWithImages from '../components/FeatureSections/FeatureSectionSideBySideWithImages';
import TestimonialSideBySideOnBrand from '../components/Testimonials/TestimonialSideBySideOnBrand';
import ContentSectionWithTestimonialAndStats from '../components/ContentSections/ContentSectionWithTestimonialAndStats';
import PricingThreeTiers from '../components/Pricing/PricingThreeTiers';
import HeaderSimpleCentered from '../components/Headers/HeaderSimpleCentered';
import BlogSectionThreeColumnCards from '../components/BlogSections/BlogSectionThreeColumnCards';
import TeamSectionGridWithLargeRoundImages from '../components/TeamSections/TeamSectionGridWithLargeRoundImages';
import ContentSectionTwoColumnWithMediaContent
    from '../components/ContentSections/ContentSectionTwoColumnWithMediaContent';
import BookingCalendlyBase from '../components/Booking/BookingCalendlyBase';
import ContentSectionHeading from '../components/ContentSections/ContentSectionHeading';
import ContentSectionSecondHeading from '../components/ContentSections/ContentSectionSecondHeading';
import ContentSectionParagraph from '../components/ContentSections/ContentSectionParagraph';
import TeamSectionTeamMember from '../components/TeamSections/TeamSectionTeamMember';
import BlogSectionBlogPage from '../components/BlogSections/BlogSectionBlogPage';
import BlogSectionContentBox from '../components/BlogSections/BlogSectionContentBox';
import FooterBasic from '../components/Footers/FooterBasic';
import ContactBasic from '../components/Contact/ContactBasic';
import MediaSectionHero from '../components/Heros/MediaSectionHero';
import FeatureSectionSimple from '../components/FeatureSections/FeatureSectionSimple';
import TeamSectionWithSmallImages from '../components/TeamSections/TeamSectionWithSmallImages';
import TopFiveInterestRatePageBlok from '../components/CompareMortgageProvider/TopFiveInterestRatePageBlok';
import NavBarOhaoWithDropDown from '../components/Navbars/NavBarOhaoWithDropDown';
import TestimonialSideBySideLargeImage from '../components/Testimonials/TestimonialSideBySideLargeImage';
import MaximumMortgageCalculation from '../components/MaximumMortgageCalculation/MaximumMortgageCalculation';
import ServiceDescriptionContainer from '../components/ServicePage/ServiceDescriptionContainer';
import ServiceSubDescription from '../components/ServicePage/ServiceSubDescription';
import StepsContainer from '../components/ServicePage/StepsContainer';
import ServiceStep from '../components/ServicePage/ServiceStep';
import ServiceSteps from '../components/ServicePage/ServiceSteps';
import FaqContainer from '../components/Faq/FaqContainer';
import SimpleHeroWithMediaBox from '../components/Heros/SimpleHeroWithMediaBox';
import ReviewBar from '../components/Reviews/ReviewBar';
import StepsSimpleCircle from '../components/Steps/StepsSimpleCircle';
import HeaderSimpleWithAuthorCentered from '../components/Headers/HeaderSimpleWithAuthorCentered';
import ReferralCodeBlock from '../components/TellAFriend/ReferralCodeBlock';
import QuickNavigation from '../components/QuickNavigation/QuickNavigation';

Vue.component('v-runtime-template', VRuntimeTemplate);
Vue.component('blok-page', Page);
Vue.component('blok-blog-post', BlogPost);
Vue.component('blok-services', Services);
Vue.component('blok-service', Service);
Vue.component('blok-hero', Hero);
Vue.component('blok-headline', Headline);
Vue.component('blok-two-button-hero', TwoButtonHero);
Vue.component('blok-sub-hero', SubHero);
Vue.component('blok-text-img', TextImg);
Vue.component('blok-pricetags', PriceTags);
Vue.component('blok-pricetag', PriceTag);
Vue.component('blok-price-main-summery', PriceMainSummary);
Vue.component('blok-testimonial-slide', TestimonialSlide);
Vue.component('blok-testimonial-slider', TestimonialSlider);
Vue.component('blok-extraservice', ExtraService);
Vue.component('blok-extraservices', ExtraServices);
Vue.component('blok-navbar', Navbar);
Vue.component('blok-top-bar', TopBar);
Vue.component('blok-footer', Footer);
Vue.component('blok-appointmentbutton', AppointmentButton);
Vue.component('blok-appointment-calendar', AppointmentCalendar);
Vue.component('blok-booking-confirmation', BookingConfirmation);
Vue.component('blok-page-block', PageBlock);
Vue.component('blok-page-block-full-height', PageBlockFullHeight);
Vue.component('blok-page-block-two-columns', PageBlockTwoColumns);
Vue.component('blok-call-to-action-wrapper', CallToActionWrapper);
Vue.component('blok-call-to-action', CallToAction);
Vue.component('blok-usps', Usps);
Vue.component('blok-usp', Usp);
Vue.component('blok-usps-big', UspsBig);
Vue.component('blok-usp-big', UspBig);
Vue.component('blok-lenders-overview', LendersOverview);
Vue.component('blok-googlemap', GoogleMap);
Vue.component('blok-contact-details', ContactDetails);
Vue.component('blok-seduce-appointment', SeduceAppointment);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('blok-text', TextBlok);
Vue.component('blok-description-list', DescriptionList);
Vue.component('blok-description-list-item', DescriptionListItem);
Vue.component('blok-image', Image);
Vue.component('blok-reviews', Reviews);
Vue.component('blok-review-grade', ReviewGrade);
Vue.component('blok-testimonials', Testimonials);
Vue.component('blok-testimonial', Testimonial);
Vue.component('blok-team-member', TeamMember);
Vue.component('blok-team-members', TeamMembers);
Vue.component('blok-step', Step);
Vue.component('blok-steps', Steps);
Vue.component('blok-table-of-contents', TableOfContents);
Vue.component('blok-table-of-content', TableOfContent);
Vue.component('blok-timeline', Timeline);
Vue.component('blok-timelines', Timelines);
Vue.component('blok-faq', Faq);
Vue.component('blok-faqs', Faqs);
Vue.component('blok-dynamic-form', DynamicForm);
Vue.component(
    'blok-compare-mortgage-provider-form',
    CompareMortgageProviderForm,
);
Vue.component(
    'blok-compare-mortgage-provider-display',
    CompareMortgageProviderDisplay,
);
Vue.component(ClientOnly);
Vue.component('blok-basic-button', BasicButton);
Vue.component('blok-basic-button-wrapper', BasicButtonWrapper);
Vue.component('blok-table-usp', TableUsp);
Vue.component('blok-table-usp-item', TableUspItem);
Vue.component('blok-container', Container);
Vue.component('blok-navbar-item', NavbarItem);
Vue.component('blok-ohao-services-front-page', OhaoServicesFrontPage);
Vue.component('blok-global', Global);
Vue.component('blok-global-reference', GlobalReference);
Vue.component('blok-navbar-links-centered', NavbarLinksCentered);
Vue.component('blok-hero-with-app-screenshot', HeroWithAppScreenshot);
Vue.component('blok-page-simple', PageSimple);
Vue.component('blok-logo-cloud-heading-brand', LogoCloudHeadingBrand);
Vue.component('blok-cta-center-branded', CtaCenterBranded);
Vue.component('blok-feature-section-side-by-side-with-images', FeatureSectionSideBySideWithImages);
Vue.component('blok-testimonial-side-by-bide-on-brand', TestimonialSideBySideOnBrand);
Vue.component('blok-cta-brand-panel-with-app-screenshot', CtaBrandPanelWithAppScreenshot);
Vue.component('blok-cta-centered', CtaCentered);
Vue.component('blok-content-section-with-testimonial-and-stats', ContentSectionWithTestimonialAndStats);
Vue.component('blok-pricing-three-tiers', PricingThreeTiers);
Vue.component('blok-header-simple-centered', HeaderSimpleCentered);
Vue.component('blok-blog-section-three-column-cards', BlogSectionThreeColumnCards);
Vue.component('blok-team-section-grid-with-large-round-images', TeamSectionGridWithLargeRoundImages);
Vue.component('blok-content-section-two-column-with-media-content', ContentSectionTwoColumnWithMediaContent);
Vue.component('blok-booking-calendly-base', BookingCalendlyBase);
Vue.component('blok-content-section-heading', ContentSectionHeading);
Vue.component('blok-content-section-second-heading', ContentSectionSecondHeading);
Vue.component('blok-content-section-paragraph', ContentSectionParagraph);
Vue.component('blok-team-section-team-member', TeamSectionTeamMember);
Vue.component('blok-blog-section-blog-page', BlogSectionBlogPage);
Vue.component('blok-blog-section-content-box', BlogSectionContentBox);
Vue.component('blok-footer-basic', FooterBasic);
Vue.component('blok-contact-basic', ContactBasic);
Vue.component('blok-media-section-hero', MediaSectionHero);
Vue.component('blok-feature-section-simple', FeatureSectionSimple);
Vue.component('blok-team-section-with-small-images', TeamSectionWithSmallImages);
Vue.component('blok-top-five-interest-rate-page-blok', TopFiveInterestRatePageBlok);
Vue.component('blok-nav-bar-ohao-with-drop-down', NavBarOhaoWithDropDown);
Vue.component('blok-testimonial-side-by-side-large-image', TestimonialSideBySideLargeImage);
Vue.component('blok-maximum-mortgage-calculation', MaximumMortgageCalculation);
Vue.component('blok-service-description-container', ServiceDescriptionContainer);
Vue.component('blok-service-sub-description', ServiceSubDescription);
Vue.component('blok-steps-container', StepsContainer);
Vue.component('blok-service-steps', ServiceSteps);
Vue.component('blok-service-step', ServiceStep);
Vue.component('blok-faq-container', FaqContainer);
Vue.component('blok-simple-hero-with-media-box', SimpleHeroWithMediaBox);
Vue.component('blok-review-bar', ReviewBar);
Vue.component('blok-steps-simple-circle', StepsSimpleCircle);
Vue.component('blok-header-simple-with-author-centered', HeaderSimpleWithAuthorCentered);
Vue.component('blok-referral-code-block', ReferralCodeBlock);
Vue.component('blok-blog-quick-navigations', QuickNavigation);
