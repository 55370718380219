
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import axios from 'axios';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                referralCode: null,
                local_utm: null,
                utmCookiesSet: false,
            };
        },
        
        mounted: function() {
            let here = this;
            window.addEventListener('message', function(e) {
                if (here.isCalendlyScheduledEvent(e)) {
                    here.getAnswersToQuestion(e.data.payload.invitee.uri);
                }
            });
            
            this.referralCode = this.getReferralCode();
            
            this.$nextTick(() => {
                this.utmCookiesSet = this.getCookieSet();
            });
        },
        
        methods: {
            getReferralCode() {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => {
                        return searchParams.get(prop);
                    },
                });
                
                return params.referralcode;
            },
            
            getCookieSet() {
                this.local_utm = this.$cookies.get('adpi_local_utm');
                return true;
            },
            
            getDataUrl(type) {
                // return "https://calendly.com/ohao-globaal/introductory-call-clone";
                
                let url = null;
                if (type === 'kennismaking' && this.langcode === 'nl') {
                    url = 'https://calendly.com/ohao-globaal/kennismaking?hide_gdpr_banner=1';
                }
                
                if (type === 'kennismaking' && this.langcode !== 'nl') {
                    url = 'https://calendly.com/ohao-globaal/introductory_call?hide_gdpr_banner=1';
                }
                
                if (type === 'tellAFriend' && this.langcode === 'nl') {
                    url = 'https://calendly.com/ohao-globaal/tell-a-friend-kennismakingsgesprek?hide_gdpr_banner=1&a5=' + this.referralCode;
                }
                
                if (type === 'tellAFriend' && this.langcode !== 'nl') {
                    url = 'https://calendly.com/ohao-globaal/tell-a-friend-introductory-call?hide_gdpr_banner=1&a5=' + this.referralCode;
                }
                
                if (this.local_utm) {
                    url = url.concat(
                        '&utm_campaign=' + this.local_utm.utm_campaign,
                        '&utm_medium=' + this.local_utm.utm_medium,
                        '&utm_source=' + this.local_utm.utm_source,
                        '&utm_content=' + this.local_utm.utm_content,
                        '&utm_term=' + this.local_utm.utm_term,
                        '&salesforce_uuid=' + this.local_utm.gclid,
                    );
                }
                
                url = encodeURI(url);
                
                return url;
            },
            
            isCalendlyScheduledEvent(e) {
                return e.origin === 'https://calendly.com' &&
                    e.data.event &&
                    e.data.event.indexOf('calendly') === 0 &&
                    e.data.event === 'calendly.event_scheduled';
            },
            getAnswersToQuestion(uri) {
                const instance = axios.create({
                    baseURL: uri,
                    timeout: 5000,
                    headers: { 'Authorization': 'Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjYzMjU0Mjg2LCJqdGkiOiIyZjk1YzNiNy02YzA4LTQyZjAtODM4My0zNDg0YzgxOTMwYTkiLCJ1c2VyX3V1aWQiOiI5NTBiYmMwOS1kNjYxLTRhYTgtOGY1OS1iMTMzMTQ1MmJlNWYifQ.olhWjnzQKrShyq0C1DrEuKzNiwRbtO8swyFlw_0Mc2h9PFdOqq_LlVDM9jRmJ4bB5htBRL_yfVm6MVhuN2VVbQ' },
                });
                let here = this;
                instance.get('/').then(function(response) {
                    here.triggerAnalytics(
                        response.data.resource.questions_and_answers[2]['answer'],
                        response.data,
                    );
                }).catch(function(error) {
                    // handle error
                    console.log(error);
                });
            },
            triggerAnalytics(answer, data) {
                const customerInformation = {
                        email: data.resource.email,
                        phone_number: data.resource.questions_and_answers?.[0]?.answer,
                }
                
                if (this.langcode === 'nl' && this.blok.type[0] === 'kennismaking') {
                    if (['Ja', 'Weet ik nog niet'].includes(answer)) {
                        this.$gtm.push({ 
                            event: 'fysiekNL',
                            ...customerInformation
                        });
                    } else {
                        this.$gtm.push({ 
                            event: 'onlineNL',
                            ...customerInformation
                        });
                    }
                }
                
                if (this.langcode !== 'nl' && this.blok.type[0] === 'kennismaking') {
                    if (['Yes', 'I don\'t know yet'].includes(answer)) {
                        this.$gtm.push({ 
                            event: 'fysiekExpat',
                            ...customerInformation
                        });
                    } else {
                        this.$gtm.push({ 
                            event: 'onlineExpat',
                            ...customerInformation
                        });
                    }
                }
                
                if (this.langcode === 'nl' && this.blok.type[0] === 'tellAFriend') {
                    this.$gtm.push({ 
                        event: 'tellAFriend',
                        ...customerInformation
                    });
                }
                
                if (this.langcode !== 'nl' && this.blok.type[0] === 'tellAFriend') {
                    this.$gtm.push({ 
                        event: 'tellAFriendExpat',
                        ...customerInformation
                    });
                }
            },
        },
    };
