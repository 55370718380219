
    import { ComponentBackgroundMixin } from '../../mixins/component-background-mixin';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { VueperSlide, VueperSlides } from 'vueperslides';
    import 'vueperslides/dist/vueperslides.css';
    
    export default {
        mixins: [ComponentBackgroundMixin, SbComponentMixin],
        components: { VueperSlides, VueperSlide },
        data: function() {
            return {
                slides: [
                    {
                        title: 'Slide #1',
                        content: 'Slide content.',
                    },
                    {
                        title: 'Slide #2',
                        content: 'Slide content.',
                    },
                    {
                        title: 'Slide #3',
                        content: 'Slide content.',
                    },
                ],
            };
        },
    };
