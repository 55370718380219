
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import VueHorizontal from 'vue-horizontal';
    
    export default {
        mixins: [SbComponentMixin],
        
        components: {
            VueHorizontal,
        },
        
        data() {
            return {
                activeIndex: 0,
            };
        },
        
        methods: {
            play() {
                // WIP Autoplay carousel
                if (!this.hasNext && this.hasPrev) {
                    this.$refs.horizontal.scrollToIndex(0);
                    return;
                }
                
                if (this.hasNext) {
                    this.$refs.horizontal.next();
                }
            },
            next() {
                this.activeIndex++;
            },
            previous() {
                this.activeIndex--;
            },
        },
    };
