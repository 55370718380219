
    export default {
        data() {
            return {
                blok: {
                    title: 'MultiInfo',
                    subTitle: 'lorem10 ipsum10 dolor10 sit10 amet10 consectetur10 adipisicing10 elit10 sed10 do10 eiusmod10 tempor10 incididunt10 ut10 labore10 et10 dolore10 magna10 aliqua10',
                    cta: 'Alle hypotheekaanbieders',
                    subInfo: [
                        {
                            title: 'Annuïteitenhypotheek',
                            content: 'Een annuïteitenhypotheek is een hypotheek waarbij de maandelijkse som van rente plus aflossing gelijk blijft gedurende de gehele looptijd. In het begin van de looptijd betaal je vooral veel rente en naarmate de hypotheek vordert ga je meer aflossen.',
                            link: {
                                title: 'Meer informatie',
                                url: 'https://www.ohao.nl/hypotheekvormen/annuiteitenhypotheek',
                            },
                        },
                        {
                            title: 'Lineaire hypotheek\n',
                            content: 'Een annuïteitenhypotheek is een hypotheek waarbij de maandelijkse som van rente plus aflossing gelijk blijft gedurende de gehele looptijd. In het begin van de looptijd betaal je vooral veel rente en naarmate de hypotheek vordert ga je meer aflossen.',
                            link: {
                                title: 'Blaba',
                                url: 'https://www.ohao.nl/hypotheekvormen/annuiteitenhypotheek',
                            },
                        },
                    ],
                },
            };
        },
    };
