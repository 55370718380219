export default {
    'title': 'Simple no-tricks pricing',
    'subTitle': 'Free introduction phase',
    'whatIsSituation': 'What is your situation?',
    'whatIsIncluded': 'What\'s included?',
    'includedOne': 'Free introduction phase',
    'includedTwo': 'Extensive advise',
    'includedThree': 'Reporting in English',
    'includedFour': 'Closing the mortgage (no additional closing costs from the provider)',
};
