
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import SmallReviews from '../Advieskeuze/SmallReviews';
    
    export default {
        components: { SmallReviews },
        mixins: [SbComponentMixin],
        
        props: {
            showCtaButton: {
                type: Boolean,
                default: true,
                required: false,
            },
        },
        
        data() {
            return {};
        },
    };
