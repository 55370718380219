
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                tiers: [
                    {
                        title: 'Starter',
                        price: '€100',
                        frequency: '/ per gebruiker / per maand',
                        description: '+ €65 per AX aanvraag',
                        features: ['5 products', 'Up to 1,000 subscribers', 'Basic analytics', '48-hour support response time'],
                        cta: 'Plan een demo',
                        mostPopular: false,
                    },
                    {
                        title: 'Kantoor',
                        price: '€270',
                        frequency: '/ per gebruiker / per maand',
                        description: 'Onbeperkt aanvragen',
                        features: [
                            '25 products',
                            'Up to 10,000 subscribers',
                            'Advanced analytics',
                            '24-hour support response time',
                            'Marketing automations',
                        ],
                        cta: 'Plan een demo',
                        mostPopular: true,
                    },
                    {
                        title: 'Enterprise',
                        price: 'Neem contact op',
                        frequency: '',
                        description: '',
                        features: [
                            'Unlimited products',
                            'Unlimited subscribers',
                            'Advanced analytics',
                            '1-hour, dedicated support response time',
                            'Marketing automations',
                            'Custom integrations',
                        ],
                        cta: 'Plan een demo',
                        mostPopular: false,
                    },
                ],
            };
        },
    };
