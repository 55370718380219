
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import TimeLine from './Timeline';
    
    export default {
        mixins: [SbComponentMixin],
        components: {
            TimeLine: TimeLine,
        },
    };
