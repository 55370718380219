
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                activeTab: '',
                activeQuestionIndex: null,
            };
        },
    };
