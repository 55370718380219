
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { rtfRender } from '/helpers/render-richtext';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {};
        },
        
        computed: {
            transformedContent() {
                if (!this.blok && !this.blok.body) return;
                
                return rtfRender(this.blok.body);
            },
        },
    };
