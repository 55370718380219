
    import { rtfRender } from '../../helpers/render-richtext';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        computed: {
            rendered() {
                if (!(this.blok && this.blok.text)) return;
                return rtfRender(this.blok.text);
            },
        },
    };
