
    import { ComponentBackgroundMixin } from '../../mixins/component-background-mixin';
    import { TextMixin } from '../../mixins/text-mixin';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { rtfRender } from '../../helpers/render-richtext.js';
    
    export default {
        mixins: [ComponentBackgroundMixin, SbComponentMixin, TextMixin],
        
        computed: {
            body: function() {
                return rtfRender(this.blok.body);
            },
            
            innerStyle: function() {
                return this.innerComputedStyle;
            },
            
            rowStyle: function() {
                return {
                    'justify-content': this.blok.centered ? 'space-between' : false,
                    'align-items': this.blok.centered ? 'center' : false,
                    'min-height': this.blok.min_height
                        ? this.blok.min_height + 'px'
                        : false,
                    'margin-top': !this.blok.margin ? null : this.blok.margin + 'px',
                    'margin-bottom': !this.blok.margin ? null : this.blok.margin + 'px',
                    padding: !this.blok.padding ? null : this.blok.padding + 'px',
                };
            },
            
            cols: function() {
                return this.blok.centered ? 12 / this.blok.blocks.length - 1 : false;
            },
        },
    };
