export const TextMixin = {
    computed: {
        computedTextStyle: function() {
            const style = {
                color: this.blok.text_color ? this.blok.text_color.color : '#000',
            };
            
            if (this.blok.font) {
                style.fontFamily = this.blok.font;
            }
            
            return style;
        },
    },
};
