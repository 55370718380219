
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import TeamSectionTeamMemberSmallImage from './TeamSectionTeamMemberSmallImage';
    
    export default {
        components: { TeamSectionTeamMemberSmallImage },
        mixins: [SbComponentMixin],
        
        data() {
            return {
                people: [
                    {
                        name: 'Whitney Francis',
                        role: 'Copywriter',
                        imageUrl:
                            'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
                        twitterUrl: '#',
                        linkedinUrl: '#',
                    },
                    // More people...
                ],
            };
        },
    };
