
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { rtfRender } from '../../helpers/render-richtext';
    
    export default {
        mixins: [SbComponentMixin],
        
        computed: {
            generated() {
                if (this.blok && this.blok.content) {
                    return rtfRender(this.blok.content);
                }
            },
            
            computedStyle() {
                return {
                    'text-align': this.blok.alignment ? this.blok.alignment : null,
                };
            },
        },
    };
