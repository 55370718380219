
    import VueFaqAccordion from './faq-accordion';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { rtfRender } from '../../helpers/render-richtext';
    
    export default {
        components: {
            VueFaqAccordion,
        },
        mixins: [SbComponentMixin],
        
        props: {
            blok: {
                type: Object,
                required: false,
                default: () => {
                },
            },
        },
        
        data() {
            return {};
        },
        
        computed: {
            myItems() {
                return this.blok.faq.map(obj => {
                    let rObj = {};
                    rObj['title'] = obj.question;
                    rObj['value'] = rtfRender(obj.answer);
                    return rObj;
                });
            },
        },
        
        methods: {
            onCategorySelect(args) {
                // console.log('onCategorySelect', args)
            },
            onItemSelect(args) {
                // console.log('onItemSelect', args)
            },
        },
    };
