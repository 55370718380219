import https from 'https';
import axios from 'axios';

const agent = new https.Agent({
    rejectUnauthorized: false,
});

const config = {
    httpsAgent: agent,
    headers: {
        'API-KEY': process.env.ADPI_KEY,
    },
};

export const getGeldverstrekkers = async function() {
    try {
        return await axios
        .get(`${process.env.ADPI_BASE_URL}/geldverstrekkers`, config)
        .then((response) => response.data);
    } catch (error) {
        console.log(error);
    }
};

export const getGeldverstrekker = async function(slug) {
    try {
        return await axios
        .get(`${process.env.ADPI_BASE_URL}/geldverstrekkers/${slug}`, config)
        .then((response) => response.data.geldverstrekker);
    } catch (error) {
        console.log(error);
    }
};

export const getInterestRatesBasic = async function(comparemortgagedetails) {
    try {
        config.params = comparemortgagedetails;
        
        return await axios
        .get(`${process.env.ADPI_BASE_URL}/compareinterestrate/basic`, config)
        .then((response) => response.data);
    } catch (error) {
        console.log(error);
    }
};

export const calculateMaximumMortgage = async function(payload) {
    try {
        const { data } = await axios.post(`${process.env.ADPI_BASE_URL}/calculate-maximum-mortgage`, payload, config);
        
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const calculateExpense = async function(payload) {
    try {
        const { data } = await axios.post(`${process.env.ADPI_BASE_URL}/calculate-expense`, payload, config);
        
        return data;
    } catch (error) {
        console.log(error);
    }
};
