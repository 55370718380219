
    import eventBus from '../../helpers/eventBus.js';
    import CompareMortgageProviderDisplay from './CompareMortgageProviderDisplay';
    
    export default {
        components: { CompareMortgageProviderDisplay },
        
        data() {
            return {
                comparemortgagedetails: {
                    paymentType: 'annuitair',
                    fixedTerm: 12,
                    nhg: 1,
                    requiredMortgage: 280000,
                    propertyValue: 280000,
                    energyCertificate: '11 A++++',
                },
            };
        },
        
        mounted: function() {
            this.$nextTick(function() {
                eventBus.$emit(
                    'comparemortgagedetails:compare',
                    this.comparemortgagedetails,
                );
            });
        },
    };
