
    import { SbComponentMixin } from '/mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        head() {
            return {
                htmlAttrs: {
                    lang: this.langcode,
                },
            };
        },
        
        data() {
            return {
                local_utm: {
                    utm_campaign: null,
                    utm_medium: null,
                    utm_source: null,
                    utm_content: null,
                    utm_term: null,
                    gclid: null,
                },
            };
        },
        
        async mounted() {
            this.$gtm.init();

            await this.acceptCookies();
        },
        
        methods: {
            async acceptCookies() {
                await this.setGtmCookieAccepted();
                await this.getUrlParams();
                await this.setCooky();
            },
            
            async declineCookies() {
                await this.setGtmCookieDeclined();
            },

            setGtmCookieAccepted() {
                this.$gtm.push({
                    'event': 'consent_update',
                    'ad_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'analytics_storage': 'granted',
                    'security_storage': 'granted',
                    'personalization_storage': 'granted',
                    'functionality_storage': 'granted'
                });            
            },
            
            setGtmCookieDeclined() {
                this.$gtm.push({
                    'event': 'consent_update',
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied',
                    'security_storage': 'granted',
                    'personalization_storage': 'granted',
                    'functionality_storage': 'granted'
                });
            },
            
            async getUrlParams() {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => {
                        return searchParams.get(prop);
                    },
                });
                
                this.local_utm.utm_campaign = params.utm_campaign;
                this.local_utm.utm_medium = params.utm_medium;
                this.local_utm.utm_source = params.utm_source;
                this.local_utm.utm_content = params.utm_content;
                this.local_utm.utm_term = params.utm_term;
                this.local_utm.gclid = params.gclid;
                
                return true;
            },
            
            async setCooky() {
                let existingCookie = this.$cookies.get('adpi_local_utm');
                
                if (!existingCookie) {
                    this.initiateCookie();
                }
            },
            
            initiateCookie() {
                this.$cookies.set('adpi_local_utm', this.local_utm, {
                    maxAge: 60 * 60 * 24 * 60,
                });
            },
        },
    };
