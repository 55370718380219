
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                isActive: false,
            };
        },
        
        methods: {
            closeMenu() {
                this.isActive = false;
            },
            openMenu() {
                this.isActive = true;
            },
        },
    };
