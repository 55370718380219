
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { rtfRender } from '/helpers/render-richtext';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                activeTab: '',
                activeQuestionIndex: null,
            };
        },
        
        methods: {
            transformedContent(content) {
                return rtfRender(content);
            },
        },
    };
