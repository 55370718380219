
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { get } from 'lodash';
    
    export default {
        mixins: [SbComponentMixin],
        
        computed: {
            style: function() {
                return {
                    'border-radius': this.blok.radius + 'px',
                    'background-color': get(this.blok, 'bg_color.color', false),
                    'color': get(this.blok, 'color.color', false),
                    'padding': this.blok.padding,
                    'border-color': get(this.blok, 'border_color.color', false),
                };
            },
        },
    };
