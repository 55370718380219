
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import * as validators from 'vuelidate/lib/validators';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                form: this.blok.inputs.reduce(
                    (prevFields, inputField) => ({
                        ...prevFields,
                        [inputField.name]: '',
                    }),
                    {},
                ),
            };
        },
        
        computed: {
            fieldRules() {
                return this.blok.inputs.reduce(
                    (prevFields, inputField) => ({
                        ...prevFields,
                        [inputField.name]: this.generateFieldRules(inputField.validators),
                    }),
                    {},
                );
            },
        },
        
        validations() {
            return {
                form: this.fieldRules,
            };
        },
        
        methods: {
            generateFieldRules(fieldValidators) {
                return fieldValidators.reduce(
                    (prevValidators, validator) => ({
                        ...prevValidators,
                        [validator.component]: validator.param
                            ? validators[validator.component](validator.param)
                            : validators[validator.component],
                    }),
                    {},
                );
            },
            
            formSubmit(e) {
                if (this.$v.form.$invalid) {
                    this.$v.form.$touch();
                    e.preventDefault();
                }
            },
        },
    };
