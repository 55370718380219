import Vue from 'vue';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faStar as falStar } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

// Import only needed PRO Light
library.add(falStar);
// Import only needed PRO Solid
library.add(fas);
// Import only needed PRO Duo
library.add(fad);
// Import brands
library.add(fab);

Vue.component('font-awesome-icon', FontAwesomeIcon);
