
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    import axios from 'axios';
    import lowercaseKeys from 'lowercase-keys';
    import moment from 'moment';
    import VueHorizontal from 'vue-horizontal';
    
    export default {
        components: {
            VueHorizontal,
        },
        
        data: function() {
            return {
                reviews: null,
                reviewcount: null,
                overallScore: null,
                currentPage: 1,
            };
        },
        
        computed: {
            maxReviews: function() {
                if (this.reviews && this.reviews.length > 2) {
                    return this.reviews;
                }
                return [];
            },
        },
        
        mixins: [SbComponentMixin],
        
        async fetch() {
            const lngDetector = new (require('languagedetect'));
            
            let reviewsReturn = await this.getReviews();
            
            const reviews = lowercaseKeys(reviewsReturn).reviews;
            
            const filteredReviews = [];
            
            reviews.forEach((i) => {
                i.language = lngDetector.detect(i.Text, 1)[0][0];
                i.more = false;
                
                if (i.Score < 4) {
                    return;
                }
                
                if (i.language === 'dutch' && this.langcode === 'nl') {
                    filteredReviews.push(i);
                    return;
                }
                
                if (i.language === 'english' && this.langcode === 'en') {
                    filteredReviews.push(i);
                }
            });
            
            this.reviews = filteredReviews;
            this.overallScore = reviewsReturn.ReviewCount.Score;
            this.reviewcount = reviewsReturn.ReviewCount.ReviewCount;
            
            this.$refs.horizontal?.refresh();
        },
        
        methods: {
            async getReviews() {
                return await axios
                .get(process.env.ADVIESKEUZE_API)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => console.log(error));
            },
            
            getDate(datestring) {
                moment.locale(this.langcode);
                return moment(datestring).format('D MMMM');
            },
            
            getSolidStars(review) {
                return new Array(review.Score);
            },
            
            getRestStars(review) {
                return new Array(5 - review.Score);
            },
            
            getText(review) {
                if (review.more) {
                    return review.Text;
                }
                return this.trimWithoutCuttingWord(review.Text, 200);
            },
            
            trimWithoutCuttingWord(string, length) {
                if (string.length > length) {
                    var trimmedString = string.substr(0, length);
                    
                    //re-trim if we are in the middle of a word and
                    return trimmedString.substr(
                        0,
                        Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
                    );
                }
                
                return string;
            },
            
            moreOrLess(review) {
                review.more = !review.more;
            },
            
            previousPage() {
                this.$refs?.horizontal?.prev();
            },
            
            nextPage() {
                this.$refs?.horizontal?.next();
            },
        },
    };
