
    import { SbComponentMixin } from '../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        computed: {
            computedStyle: function() {
                return {
                    color: this.blok.text_color.color,
                    'background-color': this.blok.backgroundcolor.color,
                    '--service-bgcolor-hover': this.blok.backgroundcolor_hover.color,
                };
            },
        },
    };
