
    import { rtfRender } from '../../helpers/render-richtext';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        computed: {
            generated() {
                if (this.blok && this.blok.answer) {
                    return rtfRender(this.blok.answer);
                }
            },
        },
    };
