
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { GenerateInfowindow } from '../../helpers/google-infowindow';
    
    export default {
        mixins: [SbComponentMixin],
        
        data: function() {
            return {
                maps: null,
                markers: [],
            };
        },
        
        watch: {
            'blok.markers': function(val) {
                if (val && this.map) {
                    this.placeMarkers();
                }
            },
        },
        
        computed: {
            element: function() {
                return document.getElementById('map-component');
            },
            
            latLong: function() {
                if (this.blok.latitude && this.blok.longitude) {
                    return {
                        lat: parseFloat(this.blok.latitude),
                        lng: parseFloat(this.blok.longitude),
                    };
                }
                return { lat: -34.397, lng: 150.644 };
            },
            
            mapStyle: function() {
                return {
                    height: this.blok.mapheight ? `${this.blok.mapheight}px` : '400px',
                };
            },
            
            isFullWidth: function() {
                return this.blok && this.blok.full_width ? true : false;
            },
            
            gestureHandling: function() {
                return this.blok && this.blok.manipulatable ? 'auto' : 'none';
            },
            
            disableDefaultUI: function() {
                return this.blok && this.blok.disable_ui;
            },
        },
        
        mounted: function() {
            this.initializeGoogleMaps();
        },
        
        methods: {
            initializeGoogleMaps: function() {
                if (window.initMap) {
                    this.$nextTick(this.initMap);
                    return;
                }
                
                var script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API}&callback=initMap`;
                script.defer = true;
                
                window.initMap = () => {
                    this.$nextTick(this.initMap);
                };
                
                document.head.appendChild(script);
            },
            
            initMap: function() {
                this.map = new google.maps.Map(this.element, {
                    center: this.latLong,
                    zoom: parseFloat(this.blok.zoom),
                    gestureHandling: this.gestureHandling,
                    disableDefaultUI: this.disableDefaultUI,
                    rotateControl:
                        !this.disableDefaultUI && this.gestureHandling !== 'none',
                });
                
                this.placeMarkers();
            },
            
            placeMarkers: function() {
                if (!this.blok || !this.blok.markers) return;
                
                if (this.markers) {
                    this.markers.forEach((m) => m.setMap(null));
                    this.markers = [];
                }
                
                var markers = [];
                this.blok.markers.forEach((marker) => {
                    var newmarker = new google.maps.Marker({
                        title: marker.title,
                        map: this.map,
                        animation: google.maps.Animation.DROP,
                        position: {
                            lat: parseFloat(marker.latitude),
                            lng: parseFloat(marker.longitude),
                        },
                    });
                    
                    if (marker.infowindow && marker.infowindow[0]) {
                        var info = GenerateInfowindow(
                            marker.infowindow[0].title,
                            marker.infowindow[0].content,
                        );
                        newmarker.addListener('click', () => {
                            info.open(this.map, newmarker);
                        });
                    }
                    
                    markers.push(newmarker);
                });
                this.markers = markers;
            },
        },
    };
