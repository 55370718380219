
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { CollapseTransition } from 'vue2-transitions';
    import { rtfRender } from '/helpers/render-richtext';
    
    export default {
        mixins: [SbComponentMixin],
        
        components: {
            CollapseTransition,
        },
        
        props: {
            stepNumber: {
                type: Number,
                default: 0,
            },
        },
        
        data() {
            return {
                isActive: false,
            };
        },
        
        methods: {
            toggleActive() {
                this.isActive = !this.isActive;
            },
            
            renderRichText(richText) {
                if (!richText) return;
                
                return rtfRender(richText);
            },
        },
    };
