
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import axios from 'axios';
    
    export default {
        mixins: [SbComponentMixin],
        
        props: {
            alignLeft: {
                type: Boolean,
                default: false,
            },
        },
        
        data: function() {
            return {
                reviewCount: null,
                overallScore: null,
            };
        },
        
        async fetch() {
            let reviewsReturn = await this.getReviews();
            
            this.reviewCount = reviewsReturn.ReviewCount.ReviewCount;
            
            let overallScore = reviewsReturn.ReviewCount.Score / 2;
            this.overallScore = Math.floor(overallScore * 10) / 10;
        },
        
        methods: {
            async getReviews() {
                return await axios
                .get(process.env.ADVIESKEUZE_API)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => console.log(error));
            },
        },
    };
