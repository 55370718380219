
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    
    export default {
        mixins: [SbComponentMixin],
        
        data() {
            return {
                referralCode: null,
            };
        },
        
        mounted: function() {
            function referralCode() {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => {
                        return searchParams.get(prop);
                    },
                });
                
                return params.referralcode;
            }
            
            this.referralCode = referralCode();
        },
        
        methods: {
            copy() {
                this.$refs.myinput.focus();
                document.execCommand('copy');
            },
            
            getReferralLink() {
                if (this.langcode === 'nl') {
                    return 'https://www.ohao.nl/via-een-vriend/?utm_campaign=tell-a-friend&utm_medium=email&utm_source=client-referral&referralcode='
                        + this.referralCode;
                }
                
                return 'https://www.ohao.nl/expats/via-a-friend/?utm_campaign=tell-a-friend-expats&utm_medium=email&utm_source=client-referral&referralcode='
                    + this.referralCode;
            },
        },
    };
