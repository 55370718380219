
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import SmallReviews from '../Advieskeuze/SmallReviews';
    import { STARTING_PRICE_DUTCH, STARTING_PRICE_ENGLISH } from '/constants/prices.js';
    
    export default {
        components: { SmallReviews },
        mixins: [SbComponentMixin],
        
        data() {
            return {};
        },
        
        methods: {
            containVideo(filename) {
                if (!filename) return false;
                
                return filename.endsWith('.mp4') || filename.endsWith('.webm');
            },
        },
        
        computed: {
            startingPrice() {
                return new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }).format(STARTING_PRICE_DUTCH);
            },
            startingPriceEnglish() {
                return new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }).format(STARTING_PRICE_ENGLISH);
            },
        },
    };
