const themeAdpi = {
    primary: {
        DEFAULT: '#1c84c6',
        '100': '#a4cee8',
        '200': '#77b5dd',
        '300': '#499dd1',
        '400': '#1c84c6',
        '500': '#166a9e',
        '600': '#114f77',
        '700': '#0b354f',
    },
};

const themeOhao = {
    primary: {
        DEFAULT: '#1c84c6',
        '100': '#a4cee8',
        '200': '#77b5dd',
        '300': '#499dd1',
        '400': '#1c84c6',
        '500': '#166a9e',
        '600': '#114f77',
        '700': '#0b354f',
    },
};

module.exports = process.env.IS_ADPI ? themeAdpi : themeOhao;
