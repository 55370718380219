export const SbComponentMixin = {
    props: ['blok', 'langcode'],
    
    mounted: function() {
        this.$storybridge.on(['input', 'published', 'change'], (event) => {
            if (event.action == 'input') {
                if (event.story.id === this.story.id) {
                    this.story.content = event.story.content;
                }
            } else {
                window.location.reload();
            }
        });
    },
    
    methods: {
        processLink: function(linkObject) {
            if (linkObject.cached_url) return linkObject.cached_url;
            if (linkObject.url) return linkObject.url;
            
            return false;
        },
    },
};
