
    import { ComponentBackgroundMixin } from '../../mixins/component-background-mixin';
    import { TextMixin } from '../../mixins/text-mixin';
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { rtfRender } from '../../helpers/render-richtext.js';
    
    export default {
        mixins: [ComponentBackgroundMixin, SbComponentMixin, TextMixin],
        
        computed: {
            body: function() {
                return rtfRender(this.blok.body);
            },
            
            backgroundImage: function() {
                if (this.blok.bg_image && this.blok.bg_image.filename) {
                    return {
                        'background-image': `url('${this.blok.bg_image.filename}')`,
                        'background-repeat': 'no-repeat',
                        'background-size': 'cover',
                    };
                }
            },
            
            button1: function() {
                return this.blok.buttons ? this.blok.buttons[0] : false;
            },
            
            button2: function() {
                return this.blok.buttons && this.blok.buttons.length > 1
                    ? this.blok.buttons[1]
                    : false;
            },
        },
    };
