export const ComponentBackgroundMixin = {
    computed: {
        computedStyle: function() {
            return {
                'background-color': this.blok.background_color
                    ? this.blok.background_color.color
                    : '#fff',
            };
        },
        
        innerComputedStyle: function() {
            return {
                'background-color': this.blok.background_color_inner
                    ? this.blok.background_color_inner.color
                    : this.blok.background_color
                        ? this.blok.background_color.color
                        : '#fff',
            };
        },
        
        computedClass: function() {
            return [this.blok.padding_class ? this.blok.padding_class : null];
        },
    },
};
