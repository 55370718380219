class Language {
    constructor(options = {}) {
        this.lang = options.lang || '';
        this.folder = options.folder || '/';
        this.default = options.default || false;
    }
}

import langs from '../langs.json';

export const getLangs = function() {
    const foundLang = langs.filter(l => l.token === process.env.STORYBLOK_TOKEN);
    
    if (!foundLang || foundLang.length === 0) {
        const message = 'Can not find language with token: ' + process.env.STORYBLOK_TOKEN;
        console.error(message);
        throw message;
    }
    
    return foundLang[0].langs;
};

export const defaultLanguage = function() {
    let returnLang = new Language();
    getLangs().forEach((lang) => {
        if (lang.default) {
            returnLang = lang;
            
        }
    });
    return returnLang;
};

export const getLanguageByUri = function(uri) {
    var lang = getLangs().filter((lang) => {
        return uri.indexOf(`/${lang.folder}`) !== -1;
    });
    
    if (lang.length > 1) {
        console.error('Multiple languages found.');
    }
    
    if (Array.isArray(lang) && lang.length === 1) {
        return lang[0];
    }
    
    const def = defaultLanguage();
    def.notFound = true;
    return def;
};
