
    import { SbComponentMixin } from '../../mixins/sb-component-mixin';
    import { loadStoryBlokData } from '../../helpers/storyblok-loader';
    import NavbarLinksCentered from './../Navbars/NavbarLinksCentered';
    
    export default {
        components: { NavbarLinksCentered },
        mixins: [SbComponentMixin],
        
        data: function() {
            return {
                navbar: null,
                footer: null,
            };
        },
        
        async fetch() {
            try {
                let path = '/global/navbar';
                
                let response = await loadStoryBlokData(this.$nuxt.context, path);
                this.navbar = response?.story?.content?.global[0];
            } catch (error) {
                console.log(path);
                console.log(error);
            }
            
            try {
                let path = '/global/footer';
                
                let response = await loadStoryBlokData(this.$nuxt.context, path);
                this.footer = response?.story?.content?.global[0];
            } catch (error) {
                console.log(path);
                console.log(error);
            }
        },
        
        head() {
            return {
                bodyAttrs: {
                    itemscope: 'itemscope',
                    itemtype: 'http://schema.org/WebPage',
                },
                title: this.blok.name,
                meta: [
                    {
                        hid: 'description',
                        name: 'description',
                        content: this.blok.metaTags,
                    },
                ],
                script: [
                    {
                        src: 'https://control-cf.yourwoo.com/jq_f65ec1.js',
                    },
                ],
            };
        },
    };
